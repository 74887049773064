<template>
	<div class="first-div">
		<div v-if="showSocial" class="columns is-mobile is-multiline">
			<div class="column is-offset-1-desktop is-10-desktop is-offset-2-fullhd is-8-fullhd is-12-touch">
				<b-button
					:loading="isLoading"
					icon-pack="st"
					icon-left="google"
					class="has-text-black has-text-left is-body-d-d is-subhead-m-m button-social"
					expanded
				>{{t.login.google}}</b-button>
				<b-button
					:loading="isLoading"
					icon-pack="st"
					icon-left="facebook"
					class="has-text-black has-text-left is-body-d-d is-subhead-m-m button-social"
					expanded
				>{{t.login.facebook}}</b-button>
			</div>
		</div>
		<div v-if="showSocial" class="separator is-body-d has-text-gray">
			<span>{{$t("shared.or")}}</span>
		</div>
		<form v-if="!forgotPassword" @submit.prevent="formSubmit" class="columns is-mobile is-multiline">
			<div class="column is-offset-2-desktop is-8-desktop is-12-touch">
				<b-input
					class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
					outlined
					autocomplete="on"
					name="email"
					type="email"
					required
					v-model="form.email"
					icon-pack="st"
					icon="envelope"
					:placeholder="$t('fields.email')"
				></b-input>
			</div>
			<div class="column is-offset-2-desktop is-8-desktop is-12-touch">
				<b-input
					class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
					outlined
					icon-pack="st"
					autocomplete="on"
					name="current-password"
					icon="password"
					type="password"
					minlength="8"
					v-model="form.password"
					required
					password-reveal
					:placeholder="$t('fields.password')"
				></b-input>
				<div class="has-text-right">
					<a
						class="is-caption-m-m is-caption-d-d has-text-blue"
						@click="setForgotPassword(true)"
					>{{t.login.forgotPassword}}</a>
				</div>
			</div>
			<div class="column is-offset-2-desktop is-8-desktop is-12-touch">
				<b-button
					expanded
					:loading="isLoading"
					class="is-headline-bold-d-d is-headline-m-m"
					type="is-black"
					native-type="submit"
				>{{t.login.login}}</b-button>
			</div>
		</form>
		<form v-else @submit.prevent="formSubmitForgotPassword" class="columns is-mobile is-multiline">
			<div class="column is-offset-2-desktop is-8-desktop is-12-touch">
				<b-input
					class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
					outlined
					autocomplete="on"
					name="email"
					type="email"
					required
					v-model="form.email"
					icon-pack="st"
					icon="envelope"
					:placeholder="$t('fields.email')"
				></b-input>
				<div class="has-text-right">
					<a
						class="is-caption-m-m is-caption-d-d has-text-blue"
						@click="setForgotPassword(false)"
					>{{$t("shared.goBack")}}</a>
				</div>
			</div>
			<div class="column is-offset-2-desktop is-8-desktop is-12-touch">
				<b-button
					v-if="!hasRecovered"
					expanded
					:loading="isLoading"
					class="is-headline-bold-d-d is-headline-m-m"
					type="is-black"
					native-type="submit"
				>{{t.login.recover}}</b-button>
				<h4 v-else class="is-healine-d has-text-centered">{{t.login.recovered}}</h4>
			</div>
		</form>
	</div>
</template>

<script>
	import { login } from "@/api/auth";

	import { forgotPassword } from "@/api/users";

	export default {
		name: "Login",
		data() {
			return {
				t: this.$t("views.Auth"),
				forgotPassword: false,
				hasRecovered: false,
				isLoading: false,
				form: {
					email: "",
					password: "",
				},
			};
		},
		props: {
			showSocial: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			formSubmit() {
				this.isLoading = true;
				login(this.form)
					.then((response) => {
						this.$buefy.toast.open({
							message: this.$t(
								"components.Auth.Login.api.login.success"
							),
							position: "is-bottom",
							type: "is-success",
						});
						this.$store.dispatch("auth/authenticateUser", response, {
							root: true,
						});
						this.$socket.emit("chat_ask_unread", this.getToken);
						
						if (response.data.body.user_type == "user") {
							this.$router.push({ name: "UserHome" });
						} else if (response.data.body.user_type == "mediator") {
							this.$router.push({ name: "PartnerHome" });
						} else {
							this.$router.push({ name: "BackOffice"})
						}
					})
					.catch((error) => {
						this.isLoading = false;
						this.$buefy.toast.open({
							message: this.$t(
								"components.Auth.Login.api.login.error"
							),
							position: "is-bottom",
							type: "is-error",
						});
					});
			},
			setForgotPassword(bol) {
				if (bol == false) this.hasRecovered = false;
				this.forgotPassword = bol;
			},
			formSubmitForgotPassword() {
				this.isLoading = true;
				forgotPassword({
					email: this.form.email,
				}).finally(() => {
					this.hasRecovered = true;
					this.isLoading = false;
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';
	.button-social:nth-of-type(1) {
		margin-bottom: $space/2;
	}
	.first-div > .columns:nth-of-type(1) {
		@include until($touch) {
			margin-bottom: unset;
		}
	}
</style>