<template>
	<div class="modal " :class="{ 'is-active': isOpen }">
		<div class="modal-background"></div>
		<div class="modal-card">
			<section class="modal-card-body has-background-primary">
				<h1 class="is-title-2-m has-text-centered has-text-white mb-6">
					Bem-vindo à Segtrade, antes de continuar escolha se é um
					mediador/parceiro de seguros ou se pretende simular um seguro.
				</h1>
				<div class="columns is-mobile">
					<div class="column is-6">
						<div class="background bg-1"></div>
						<b-button
							class="btn"
							type="is-primary"
							@click="choosePartner"
							>Sou um Mediador/ Parceiro</b-button
						>
					</div>
					<div class="column is-6">
						<div class="background bg-2"></div>
						<b-button
							class="btn"
							type="is-black"
							@click="chooseUser"
							>Simular um seguro</b-button
						>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'UserPopup',
		data() {
			return {
				isOpen: false,
			};
		},
		created() {
			if (!this.$store.getters['app/getPopupType']) this.isOpen = true;
		},
		methods: {
			choosePartner() {
				this.$store.commit('app/setPopupType', 'partner');
				this.$router.push({ name: 'PartnerLandingPage' });
				this.isOpen = false;
			},
			chooseUser() {
				this.$store.commit('app/setPopupType', 'user');
				this.$router.push({ name: 'LandingPage' });
				this.isOpen = false;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';
	.modal-card-body {
		border-radius: 15px;
	}
	.btn {
		position: absolute;
		bottom: 3rem;
		left: 50%;
		transform: translateX(-50%);
		
		@include until($touch) {
			height: 80px;
			width: 100px;
			white-space: break-spaces;
		}
	}
	.column {
		background-position: center;
		height: 300px;
		background-size: cover;
		position: relative;
	}
	.background {
		position: absolute;
		top: 0%;
		left: 0%;
		width: 100%;
		height: 100%;
		background-color: rgba(34, 34, 34, 0.7);
	}

	.bg-1 {
		border-radius: 15px 0px 0px 15px;
	}
	.bg-2 {
		border-radius: 0px 15px 15px 0px;
	}

	.column:hover .background {
		opacity: 0;
	}

	.column:first-of-type {
		background-image: url('../../assets/imgs/Partner/LandingPage/1.png');
		border-radius: 15px 0px 0px 15px;
	}
	.column:nth-of-type(2) {
		background-image: url('../../assets/imgs/LandingPage/1.png');
		border-radius: 0px 15px 15px 0px;
	}
</style>
