<template>
	<div class="first-div">
		<p class="has-text-black has-text-centered mb-10 is-headline-bold-d-d is-body-m-m">
			{{ t.form_title }}
		</p>
		<form @submit.prevent="formSubmit" class="columns is-mobile is-multiline">
			<div class="column is-12-touch is-8-desktop is-offset-2-desktop">
				<b-field :type="invalids.includes('business_name') ? 'is-danger' : ''">
					<b-input
						class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
						outlined
						required
						autocomplete="on"
						name="business-name"
						v-model="form.business_name"
						:placeholder="$t('fields.business_name')"
					></b-input>
				</b-field>
			</div>
			<div class="column is-6-touch is-4-desktop is-offset-2-desktop">
				<b-field :type="invalids.includes('name') ? 'is-danger' : ''">
					<b-input
						class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
						outlined
						required
						autocomplete="on"
						name="given-name"
						v-model="form.name"
						:placeholder="$t('fields.name')"
					></b-input>
				</b-field>
			</div>
			<div class="column is-6-touch is-4-desktop">
				<b-field :type="invalids.includes('surname') ? 'is-danger' : ''">
					<b-input
						class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
						outlined
						required
						autocomplete="on"
						name="family-name"
						v-model="form.surname"
						:placeholder="$t('fields.surname')"
					></b-input>
				</b-field>
			</div>
			<div class="column is-12-touch is-offset-2-desktop is-8-desktop">
				<b-field :type="invalids.includes('email') ? 'is-danger' : ''">
					<b-input
						class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
						:class="{ 'is-danger': invalids.includes('email') }"
						outlined
						icon-pack="st"
						icon="envelope"
						autocomplete="on"
						name="email"
						type="email"
						v-model="form.email"
						required
						:placeholder="$t('fields.email')"
					></b-input>
				</b-field>
			</div>
			<div class="column is-12-touch is-offset-2-desktop is-8-desktop">
				<b-field :type="invalids.includes('phone') ? 'is-danger' : ''">
					<b-input
						class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
						outlined
						icon-pack="st"
						icon="phone"
						autocomplete="on"
						name="phone"
						type="tel"
						v-model="form.phone"
						required
						:placeholder="$t('fields.phone')"
					></b-input>
				</b-field>
			</div>
			<div class="column is-12-touch is-offset-2-desktop is-8-desktop is-caption-m has-text-black">
				<b-field :type="invalids.includes('asf_number') ? 'is-danger' : ''">
					<b-input
						class="st-line-input is-caption-d-d is-subhead-m-m has-text-black is-danger"
						outlined
						autocomplete="on"
						name="asf_number"
						type="text"
						v-model="form.asf_number"
						:placeholder="$t('fields.asf_number')"
					></b-input>
				</b-field>
			</div>
			<div class="column is-12-touch is-offset-2-desktop is-8-desktop is-caption-m has-text-black">
				<b-field :type="invalids.includes('postal_code') ? 'is-danger' : ''">
					<b-input
						class="st-line-input is-caption-d-d is-subhead-m-m has-text-black"
						:class="{ 'is-danger': invalids.includes('postal_code') }"
						outlined
						autocomplete="on"
						name="postal_code"
						type="text"
						v-model="form.postal_code"
						:placeholder="$t('fields.postal_code')"
					></b-input>
				</b-field>
			</div>
			<div class="column is-12-touch is-offset-2-desktop is-8-desktop mt-6">
				<b-button expanded class="is-headline-bold-d-d is-headline-m-m" type="is-black" native-type="submit">{{ t.register }}</b-button>
			</div>
			<div class="column is-offset-2-desktop is-8-desktop is-hidden-touch mt-6">
				<h4 class="has-text-black is-caption-d has-text-centered">
					{{ t.conf }}
				</h4>
				<h4 class="has-text-black is-caption-d has-text-centered">
					{{ t.conf1 }}
				</h4>
			</div>
		</form>
	</div>
</template>

<script>
	import { createMediator } from '@/api/users';

	export default {
		name: 'Register',
		data() {
			return {
				t: this.$t('views.Partner.Auth'),
				invalids: [],
				form: {
					email: '',
					name: '',
					surname: '',
					phone: '',
					business_name: '',
					asf_number: '',
					postal_code: '',
				},
			};
		},
		methods: {
			formSubmit() {
				createMediator(this.form)
					.then((response) => {
						this.$buefy.toast.open({
							message: this.$t('components.Partner.Register.api.createMediator.success'),
							position: 'is-top',
							type: 'is-primary',
						});
						this.$router.go(-1);
					})
					.catch((error) => {
						if (Array.isArray(error.response.data)) {
							error.response.data.forEach((element) => {
								this.invalids.push(element.param);
							});
						}

						let message = this.$t('components.Partner.Register.api.createMediator.error');

						this.$buefy.toast.open({
							message: message,
							position: 'is-top',
							type: 'is-danger',
						});
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';

	.first-div > .columns:nth-of-type(1) {
		@include until($touch) {
			margin-bottom: unset;
		}
	}
</style>
