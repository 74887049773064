import {
    get,
    post,
    put,
    del,formDataPut
} from '@/utils/http';
const queryString = require("query-string");

export function createUser(body) {
    return post('/segtrade/users/', body);
}
export function activateUser(query = {},body) {
    let _query = "?" + queryString.stringify(query);
    return put('/segtrade/users/activate'+ _query, body);
}
export function updateUser(body) {
    return put('/segtrade/users/', body);
}
export function createMediator(body) {
    return post('/segtrade/users/mediators/', body);
}

export function forgotPassword(body) {
    return post("/segtrade/users/password/recover", body);
}

export function forgotPasswordUpdate(body) {
    return put("/segtrade/users/password/recover", body);
}
export function updatePassword(body) {
    return put("/segtrade/users/password/", body);
}
export function deleteUser(id) {
    return del('/segtrade/users/' + id);
}

export function getUsers(query = {}){
    let _query = "?" + queryString.stringify(query)
    return get('/segtrade/users/'+ _query);
}

export function approveMediator(id) {
    return put('/segtrade/users/mediators/' + id);
}

export function getActivities() {
    return get('/segtrade/activities');
}

export function updatePicture(id, file) {
    let form = new FormData()
    form.append('image', file);
    return put('/segtrade/users/' + id, form);
}