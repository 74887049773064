<template>
	<nav
		ref="navbar"
		id="navbar"
		class="navbar is-fixed-top"
		:class="navClass"
		role="navigation"
		aria-label="main navigation"
	>
		<div class="navbar-brand">
			<a class="navbar-item" @click="$router.push({name:'PartnerLandingPage'})">
				<img v-if="navClass != 'is-white'" src="@/assets/icons/logo.svg" alt="SegTrade" />
				<img v-else src="@/assets/icons/logo_black.svg" alt />
			</a>

			<div class="is-hidden-desktop">
				<b-button class="is-body-m" @click="$router.push({name: 'PartnerAuth'})" :type="getBtnClass" outlined>{{t.account}}</b-button>
			</div>
		</div>

		<div class="navbar-menu">
			<div class="navbar-end">
				<div class="navbar-item is-body-d" :class="{'has-text-weight-bold': section=='home' }">
					<a :class="{'has-text-white': navClass == 'is-blue' }" @click="scrollTo('PartnerLandingPage', 'home')">{{t.home}}</a>
				</div>
				<div class="navbar-item is-body-d" :class="{'has-text-weight-bold': section=='why' }">
					<a :class="{'has-text-white': navClass == 'is-blue' }" @click="scrollTo('PartnerLandingPage', 'why')">{{t.why}}</a>
				</div>
				<div class="navbar-item is-body-d" :class="{'has-text-weight-bold': section=='how' }">
					<a :class="{'has-text-white': navClass == 'is-blue' }" @click="scrollTo('PartnerLandingPage', 'how')">{{t.how}}</a>
				</div>
				<div class="navbar-item is-body-d" :class="{'has-text-weight-bold': section=='about' }">
					<a :class="{'has-text-white': navClass == 'is-blue' }" @click="scrollTo('PartnerLandingPage', 'about')">{{t.about}}</a>
				</div>
				<div class="navbar-item is-body-d">
					<div class="buttons">
						<b-button v-if="$route.name == 'PartnerLandingPage'" class="is-desktop" @click="$router.push({name: 'PartnerAuth'})" :type="getBtnClass" outlined>{{t.login}}</b-button>
						<b-button v-else class="is-desktop" @click="$router.go(-1)" type="is-white">{{t.back}}</b-button>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
	export default {
		name: "PartnerNavbar",
		props: {
			navClass: {
				type: String,
				default: "is-black",
			},
			section: {
				type: String,
				default: "home",
			},
		},
		data() {
			return {
				t: this.$t("components.Partner.LandingPage.PartnerNavbar"),
				btnType: "is-white",
			};
		},
		mounted() {
			addEventListener("resize", () => {
				this.$store.commit(
					"css/setNavbarHeight",
					this.$refs.navbar.clientHeight
				);
			});
			this.$store.commit(
				"css/setNavbarHeight",
				this.$refs.navbar.clientHeight
			);
		},
		computed: {
			getBtnClass() {
				if (this.navClass == "is-white") {
					return "is-black";
				}
				return "is-white";
			},
		},
	};
</script>
<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	.navbar {
		padding: 64px;

		.navbar-end {
			align-items: center;
		}
	}
	img {
		width: 35.55px;
		height: 40.61px;
		max-height: unset !important;
	}

	.navbar-item:not(:last-of-type) {
		height: 50px;
		a {
			width: 100%;
			text-align: center;
		}
	}
	.navbar-item:nth-of-type(3) {
		width: 9em;
	}
	.navbar-item:nth-of-type(4) {
		margin-right: 6em;
	}
	.navbar-item,
	.button {
		font-size: 18px;
	}
	.button.is-desktop {
		padding: 14px 37px;
		width: 168px;
		height: 50px;
		border-radius: 15px;
	}
	.is-hidden-desktop {
		align-self: center;
		margin-left: auto;
		.button {
			font-size: 16px;
		}
	}

	.is-blue {
		background-color: $blue;
		a {
			color: $blue-invert;
		}
	}
	.is-black {
		background-color: $black;
		a {
			color: $black-invert;
		}
	}
	.is-white {
		background-color: $white;
		a {
			color: $white-invert;
		}
	}
</style>