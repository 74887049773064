<template>
	<div>
		<PartnerNavbar class="is-hidden-touch" :navClass="navClass" :section="section" />
		<section class="has-background-black is-hidden-touch" :style="paddingTop">
			<div class="columns is-mobile is-multiline">
				<div class="column is-5-desktop is-offset-1-desktop is-12-touch">
					<h3 v-if="activeTab == 'new_account'" class="has-text-white is-title-1-d-d">{{t.txt}}</h3>
					<h3 v-else class="has-text-white is-title-1-d-d">{{t.txt2}}</h3>
					<img :src="require('@/assets/imgs/Partner/register.png')" alt />
				</div>
				<div class="column is-5-desktop is-12-touch">
					<b-tabs class="has-background-white full-height" id="PartnerAuth-tab" position="is-centered" type="is-boxed" expanded v-model="activeTab">
						<b-tab-item class="st-tab-item has-backgroun-black" id="new_account" value="new_account" :label="t.new_account">
							<Register />
						</b-tab-item>

						<b-tab-item class="st-tab-item" id="login" value="login" :label="t.same_account">
							<Login />
						</b-tab-item>
					</b-tabs>
				</div>
			</div>
		</section>
		<section class="has-background-black is-hidden-desktop">
			<div class="columns is-mobile is-multiline">
				<div class="column is-offset-1 is-11">
					<img @click="$router.push({name:'PartnerLandingPage'})" :src="require('@/assets/icons/logo.svg')" alt />
				</div>
				<div class="column is-offset-1 is-6">
					<h4 class="is-title-1-m has-text-white">{{t.welcome}}</h4>
				</div>
				<div class="column is-12">
					<img :src="require('@/assets/imgs/Partner/register.png')" alt />
				</div>
			</div>
			<div class="columns is-mobile is-multiline has-background-white">
				<div v-if="activeTab == 'new_account'" class="column is-offset-1 is-10 pt-16">
					<h4 class="has-text-black is-headline-m">{{t.txt}}</h4>
					<Register/>
					<b-button
						expanded
						class="is-headline-m-m has-text-black button-account"
						type="is-ghost"
						@click="activeTab='login'"
					>{{t.have_account}}</b-button>
				</div>
				<div v-else class="column is-offset-1 is-10 pt-16">
					<h4 class="has-text-black is-headline-m">{{t.txt}}</h4>
					<Login />
					<b-button
						expanded
						class="is-headline-m-m has-text-black button-account"
						type="is-ghost"
						@click="activeTab='new_account'"
					>{{t.register_account}}</b-button>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	// @ is an alias to /src
	import PartnerNavbar from "@/components/Partner/LandingPage/PartnerNavbar";
	import Register from "@/components/Partner/Auth/Register";
	import Login from "@/components/Auth/Login";

	export default {
		name: "PartnerAuth",
		components: {
			PartnerNavbar,
			Register,
			Login
		},
		data() {
			return {
				t: this.$t("views.Partner.Auth"),
				navClass: "is-black",
				section: null,
				activeTab: "login",
			};
		},
		computed: {
			paddingTop() {
				return {
					"padding-top": this.isMobile
						? 0
						: `${this.$store.getters["css/getNavbarHeight"]}px`,
				};
			},
		},
		methods: {},
	};
</script>

<style lang="scss" scoped>
		@import '@/assets/scss/variables/all';
	@import '~bulma/sass/utilities/mixins.sass';
	section {
		min-height: 100vh;
	}
	section.is-hidden-touch {
		overflow: hidden;
		img {
			margin-top: 2 * $space;
		}

		.st-tab-item {
			margin-top: 2 * $space;
			outline: unset;
		}

		#new_account > div.columns.is-mobile.is-multiline {
			> div:nth-child(4) {
				margin-top: $space;
			}
			> div:nth-child(3) {
				margin-top: $space;
			}
		}
	}

	section.is-hidden-desktop {
		padding-top: 1.5 * $space;
		position: relative;
		overflow-y: scroll;
		overflow-x: hidden;

		> .columns:nth-of-type(1) {
			position: relative;
			padding-bottom: 5vh;

			> .column:nth-of-type(2) {
				z-index: 2;
			}
			> .column:nth-of-type(3) {
				position: absolute;
				left: 59vw;
				@include until($mobile-s) {
					top: -9vh;
				}
				@include until($mobile-m) {
					top: -8vh;
				}
				@include from($mobile-m) {
					top: -7vh;
				}

				padding: 0;
				z-index: 1;
				img {
					height: 36vh;
				}
			}
		}
		> .columns:nth-of-type(2) {
			z-index: 2;
			position: absolute;
			padding-bottom: 5vh;
			h4 {
				margin-top: $space;
				margin-bottom: $space;
			}
		}

		.button-account {
			margin-top: $space/2;
		}
		.button-account:focus {
			box-shadow: unset;
		}
	}

	.full-height{
		min-height: 100%;
	}
	.b-tabs nav {
		background-color: #222222;
	}
</style>